import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./pages/App";
import Location from "./pages/Location";
import { createHashRouter, RouterProvider } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import Partner from "./pages/Partner";
import Error from "./pages/Error";
import Kiosk1 from "./pages/Kiosk1";
import Kiosk2 from "./pages/Kiosk2";
import Kiosk3 from "./pages/Kiosk3";

let router = createHashRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <Error />,
  },
  {
    path: "/location/:dataSource/:locationId/:pageState",
    element: <Location />,
  },
  {
    path: "/partner/:dataSource/:locationId",
    element: <Partner />,
  },
  {
    path: "/kiosk1",
    element: <Kiosk1 />,
  },
  {
    path: "/kiosk2",
    element: <Kiosk2 />,
  },
  {
    path: "/kiosk3",
    element: <Kiosk3 />,
  },
]);

if(process.env.REACT_APP_MODE === "shareable") {
  router = createHashRouter([
    {
      path: "/location/:dataSource/:locationId/:pageState",
      element: <Location />,
      errorElement: <Error />,
    },
    {
      path: "*",
      element: <Location />,
      errorElement: <Error />,
    }
  ]);
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
