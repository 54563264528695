import React from "react";

const Component1 = () => {
  return (
    <div className="flex top-60 right-20 absolute p-4 bg-red-500 border-4 text-white w-[500px] border-white">
      <div className="mr-8">
        <div className="mb-4 w-[150px]">
          <img
            src="png/component1-1.png"
            width="300"
            height="300"
            alt="compoents1-1"
          />
          <p className="text-lg font-semibold">
            StormGuard<sup>&#174;</sup>
          </p>
        </div>
        <div>
          <img
            src="png/component1-2.png"
            width="300"
            height="300"
            alt="compoent1-2"
          />
          <p className="text-lg font-semibold">
            WeatherWatch<sup>&#174;</sup>
          </p>
        </div>
      </div>
      <div>
        <h1 className="font-bold text-4xl mb-4">Leak Barrier</h1>
        <p className="text-xl font-semibold mb-3 leading-6">
          StormGuard<sup>&#174;</sup> Film-Surfaced Leak Barrier Helps prevent
          leaks due to water backing up in your gutters, wind-driven rain, and
          in the North, damaging ice dams.
        </p>
        <p className="text-xl font-semibold leading-6">
          WeatherWatch<sup>&#174;</sup> in the South helps provide critical
          protectiono against wind driven rain.
        </p>
      </div>
      <div className="absolute -left-[25px] top-[12px] arrow-left-white" />
      <div className="absolute -left-[20px] arrow-left" />
    </div>
  );
};

export default Component1;
