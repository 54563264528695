import React from "react";

const Component5 = () => {
  return (
    <div className="flex absolute p-4 top-10 right-10 bg-red-500 border-4 text-white w-[500px] border-white">
      <div className="mr-8">
        <div className="mb-4 w-[150px]">
          <img
            src="png/component5-1.png"
            width="300"
            height="300"
            alt="compoent5-1"
          />
          <p className="text-lg font-semibold">
            Cobra<sup>&#174;</sup> Rigid Vent 3TM Exhaust Vent
          </p>
        </div>
      </div>
      <div>
        <h1 className="font-bold text-4xl mb-4">Ventilation</h1>
        <p className="text-xl font-semibold mb-3 leading-6">
          Cobra<sup>&#174;</sup>Rigid Vent3TM Exhaust Vent for Roof Ridge allows
          heat and moisture to escape at the ridge.
        </p>
      </div>
      <div className="absolute -left-[25px] top-[12px] arrow-left-white" />
      <div className="absolute -left-[20px] arrow-left" />
    </div>
  );
};

export default Component5;
