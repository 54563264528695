import React from "react";

export default function RoofButton({
  title,
  id,
  totalStore,
  regionRank,
  totalRegion,
  onClick,
  isUp,
}) {
  const handleClick = () => {
    if (onClick) {
      onClick(); // Call the onClick handler passed from the parent component
    }
  };

  return (
    <button
      className={`roof-button flex items-center justify-between gap-5 absolute`}
      onClick={handleClick}
      title={title}
    >
      <figure
        className={`roof-button-icon flex align-center justify-center rounded-full bg-stone-50 border-solid border-2  ${
          isUp ? "border-green" : "border-[#D71920]"
        }`}
      >
        <img src={`svg/${id}.svg`} width={50} alt={id} />
      </figure>
      <div className="min-w-[250px]">
        <h4 className="text-lg text-stone-50">{title}</h4>
        <p
          className={`roof-button-flag  text-stone-50 rounded py-2 px-5 text-lg font-semibold ${
            isUp ? "bg-green" : "bg-[#D71920]"
          }`}
        >
          {totalStore} % | {regionRank} of {totalRegion}
        </p>
      </div>
    </button>
  );
}
