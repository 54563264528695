import React from "react";

export default function IncrementalCell({
  incrementalSales,
  incrementState,
  pointFiveIncrement,
  onePercentIncrement,
}) {
  let incrementValue = incrementalSales;

  if (incrementState === "pointFiveIncrease") {
    incrementValue = pointFiveIncrement;
  } else if (incrementState === "onePercentIncrease") {
    incrementValue = onePercentIncrement;
  } else {
    incrementValue = incrementalSales;
  }

  const increment = new Intl.NumberFormat("us-US", {
    style: "currency",
    currency: "USD",
  })
    .format(Math.round(incrementValue))
    .slice(0, -3);

  return (
    <p className="incremental-cell text-3xl bg-[#D71920] text-stone-50 text-center py-4">
      {increment}
    </p>
  );
}
