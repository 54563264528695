import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import readXlsxFile from "read-excel-file";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../../components/Header";

export default function Partner() {
  let { locationId } = useParams();
  let { dataSource } = useParams();
  let spreadsheet = "";
  const navigator = useNavigate();
  const [email, setEmail] = useState("");
  const [choices, setChoices] = useState([]);
  const [data, setData] = useState();

  //Are we pulling the store data or market/region data?
  if (dataSource === "store") {
    spreadsheet = "data_home_depot_2023.xlsx";
  } else {
    spreadsheet = "data_home_depot_market_2023.xlsx";
  }

  useEffect(() => {
    const getData = async () => {
      fetch(spreadsheet)
        .then((response) => response.blob())
        .then((blob) => readXlsxFile(blob))
        .then((rows) => {
          const filteredRow = rows.filter((row) => {
            return parseInt(row[7]) === parseInt(locationId);
          });
          setData(filteredRow[0]);
        });
    };
    getData();
  }, [locationId, spreadsheet]);

  const onSubmit = () => {
    const formData = localStorage.getItem("forms");
    let array = JSON.parse(formData) || [];
    const form = {
      order: formData ? array?.length : 0,
      title: "Home Depot Show",
      time: Date.now(),
      store: locationId,
      email: email,
      choices: choices,
      name: data[8],
      phone: data[9],
      form: "partner",
    };
    array.push(form);
    localStorage.setItem("forms", JSON.stringify(array));
    toast.success("Email Submitted Successfully!");
  };

  const handleCheckbox = (value) => {
    if (choices.includes(value)) {
      setChoices(choices.filter((item) => item !== value));
    } else {
      setChoices([...choices, value]);
    }
  };

  return (
    <div className="h-screen flex flex-col bg-white  w-full">
      <Header title="Your RSR Partner" />
      <div className="flex justify-between items-center h-full px-40 gap-20 relative">
        <img
          src="png/arrow_prev.png"
          alt="prev"
          className="absolute left-10 cursor-pointer"
          onClick={() => navigator(-1)}
        />
        <div className="flex flex-col w-1/2">
          <h1 className="text-6xl mb-14">
            Your GAF Retail Service Representative
          </h1>
          {data && (
            <div className="flex flex-col items-start gap-4">
              <h2 className="flex flex-col text-7xl font-semibold">
                {data[8]}
              </h2>
              <p className="text-2xl mb-14">{data[53]}</p>
            </div>
          )}
          <h2 className="text-2xl mt-6">
            Your GAF RSR is your local business partner dedicated to working
            with your team inside and outside of the store to identify market
            opportunities, prospect for new contractors, strengthen existing
            contractor relationships, build loyalty, and collectively grow the
            business.
          </h2>
        </div>
        <div className="flex-col w-1/2 gap-6">
          <div className="partner-form bg-[#F6F6F6] py-8 px-12">
            <p className="text-2xl font-medium mb-9 font-semibold">
              Enter your email address to have your RSR contact you about your
              2024 roofing business plan.
            </p>
            <input
              className="px-4 py-2 border mb-16 w-full text-2xl py-4 px-12"
              placeholder="Enter Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <p className="text-2xl font-medium mb-3 font-semibold">
              What would you like more information about?
            </p>
            <input
              className="mr-2"
              type="checkbox"
              id="salesData"
              name="salesData"
              value="Sales Data"
              checked={choices.includes("Sales Data")}
              onChange={() => handleCheckbox("Sales Data")}
            />
            <label htmlFor="salesData" className="text-2xl">
              Sales Data
            </label>
            <br />
            <input
              className="mr-2"
              type="checkbox"
              id="training"
              name="training"
              value="Training"
              checked={choices.includes("Training")}
              onChange={() => handleCheckbox("Training")}
            />
            <label htmlFor="training" className="text-2xl">
              Training
            </label>
            <br />
            <input
              className="mr-2"
              type="checkbox"
              id="sampleKit"
              name="sampleKit"
              value="Sample Kit"
              checked={choices.includes("Sample Kit")}
              onChange={() => handleCheckbox("Sample Kit")}
            />
            <label htmlFor="sampleKit" className="text-2xl">
              Sample Kit
            </label>
            <br />
            <input
              className="mr-2"
              type="checkbox"
              id="productInfo"
              name="productInfo"
              value="Product Information"
              checked={choices.includes("Product Information")}
              onChange={() => handleCheckbox("Product Information")}
            />
            <label htmlFor="productInfo" className="text-2xl">
              Product Information
            </label>
            <br />
            <input
              className="mr-2"
              type="checkbox"
              id="other"
              name="other"
              value="Other"
              checked={choices.includes("Other")}
              onChange={() => handleCheckbox("Other")}
            />
            <label htmlFor="other" className="text-2xl">
              Other
            </label>
            <br />
            <button
              className="bg-red-500 py-2 px-20 mt-3 text-white text-2xl w-full"
              onClick={onSubmit}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      <ToastContainer position="bottom-left" autoClose={10000} />
    </div>
  );
}
