import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";

// import { countDownTime } from "../../utils/data";
import Button from "../../components/Button";
import components from "../../assets/components.json";
import Component1 from "../../components/Component1";
import Component2 from "../../components/Component2";
import Component3 from "../../components/Component3";
import Component4 from "../../components/Component4";
import Component6 from "../../components/Component6";
import Component5 from "../../components/Component5";

// const { ipcRenderer } = window.require("electron");

function Kiosk1() {
  const [component, setComponent] = useState(1);
  const [toggle, setToggle] = useState(false);
  const videoPlayer = useRef(null);

  const handleComponent = (item) => {
    setComponent(components[item].id);
    setToggle(false);
    videoPlayer.current.currentTime = components[item].time;
  };

  const onTimeUpdate = () => {
    for (let i = 1; i <= Object.keys(components).length; i++) {
      if (
        components[i].time < videoPlayer.current.currentTime &&
        components[i].endTime > videoPlayer.current.currentTime
      )
        setComponent(i);
    }
    if (videoPlayer.current.currentTime > components[component].startTime)
      setToggle(true);
    if (videoPlayer.current.currentTime > components[component].endTime) {
      setComponent(0);
      setToggle(false);
    }
    if (
      videoPlayer.current.currentTime >
      components[Object.keys(components).length].endTime
    ) {
      // setComponent(0);
      setToggle(false);
    }

    // if (
    //   videoPlayer.current.currentTime > components[component].endTime &&
    //   videoPlayer.current.currentTime < components[component + 1].startTime
    // )
    //   setComponent(0);
  };

  return (
    <div className="App h-screen justify-center items-center bg-black flex relative">
      <video
        ref={videoPlayer}
        src="Roof_System_master-01.mp4" // You can download video from https://phpstack-264451-3122782.cloudwaysapps.com/Roof_System_master-01.mp4 and add to build/public folder or temporarily change src here to use cloud version
        //src="https://phpstack-264451-3122782.cloudwaysapps.com/Roof_System_master-01.mp4"
        autoPlay
        loop
        onTimeUpdate={() => onTimeUpdate()}
      />
      <div className="flex items-center absolute w-[670px] h-[70px] bottom-14 bg-gray-200 px-10 rounded-full">
        <div className="flex-wrap font-bold text-[16px] leading-5 w-32">
          SYSTEM COMPONENTS
        </div>
        <div className="flex absolute right-6">
          {Object.keys(components).map((item) => (
            <Button
              onClick={() => handleComponent(item)}
              num={item}
              key={item}
              isActive={component === parseInt(item)}
            >
              {item}
            </Button>
          ))}
        </div>
      </div>
      {component === 1 && toggle === true && <Component1 />}
      {component === 2 && toggle === true && <Component2 />}
      {component === 3 && toggle === true && <Component3 />}
      {component === 4 && toggle === true && <Component4 />}
      {component === 5 && toggle === true && <Component5 />}
      {component === 6 && toggle === true && <Component6 />}

      <Link className="absolute left-0 bottom-0 w-14 h-14" to="/" />
    </div>
  );
}

export default Kiosk1;
