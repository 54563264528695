import React from "react";

const Component3 = () => {
  return (
    <div className="flex absolute right-20 top-[500px] p-4 bg-red-500 border-4 text-white w-[500px] border-white">
      <div className="mr-8">
        <div className="mb-4 w-[150px]">
          <img
            src="png/component3-1.png"
            width="300"
            height="300"
            alt="compoent3-1"
          />
          <p className="text-lg font-semibold">
            Pro-Start<sup>&#174;</sup>Starter Strip Shingles
          </p>
        </div>
      </div>
      <div>
        <h1 className="font-bold text-4xl mb-4">Starter Strip Shingles</h1>
        <p className="text-xl font-semibold mb-3 leading-6">
          Pro Start<sup>&#174;</sup> starter strip shingles help guard against
          shingle blow-offs. Pre-cut eave/rake starter strips are one of the
          most overlooked parts of a roofing system!
        </p>
      </div>
      <div className="absolute -left-[25px] top-[12px] arrow-left-white" />
      <div className="absolute -left-[20px] arrow-left" />
    </div>
  );
};

export default Component3;
