import React from "react";

const Component4 = () => {
  return (
    <div className="flex absolute bottom-28 right-60 p-4 bg-red-500 border-4 text-white w-[500px] border-white">
      <div className="mr-8">
        <div className="mb-4 w-[150px]">
          <img
            src="png/component4-1.png"
            width="300"
            height="300"
            alt="compoent4-1"
          />
          <p className="text-lg font-semibold">
            Timberline HDZ<sup>&#174;</sup>Shingles
          </p>
        </div>
      </div>
      <div>
        <h1 className="font-bold text-4xl mb-4">Shingles</h1>
        <p className="text-xl font-semibold mb-3 leading-6">
          GAF Timberline<sup>&#174;</sup> Lifetime Architectural Roofing
          Shingles - North America's #1 Selling Shingle
        </p>
      </div>
      <div className="absolute -left-[25px] top-[12px] arrow-left-white" />
      <div className="absolute -left-[20px] arrow-left" />
    </div>
  );
};

export default Component4;
