import React from "react";

export default function Item({ children, className }) {
  return (
    <div
      className={`py-2 flex flex-col justify-center items-center w-full ${className}`}
    >
      {children}
    </div>
  );
}
