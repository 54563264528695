import React from "react";

export default function Card({ title, children }) {
  return (
    <div>
      <div className="py-3 px-6 bg-black font-medium text-lg text-white text-center">
        {title}
      </div>
      <div className="flex flex-col grow justify-center bg-[#f6f6f6] text-black min-370">
        {children}
      </div>
    </div>
  );
}
