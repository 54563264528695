import React from "react";

export default function Title({ variant, children }) {
  switch (variant) {
    case "h1":
      return <p className="font-bold text-lg text-center">{children}</p>;
    case "h2":
      return (
        <p className="font-bold text-base text-center uppercase">{children}</p>
      );
    case "h3":
      return <p className="font-bold text-sm text-center pb-2">{children}</p>;
    default:
      return (
        <p className="font-bold text-xs text-center uppercase">{children}</p>
      );
  }
}
