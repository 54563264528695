import React from "react";
import Title from "../Title";
import Item from "../Item";

export default function GAFCard({
  title,
  id,
  totalStore,
  regionRank,
  marketRank,
  totalRegion,
  totalMarket,
  detailView,
  dataSource,
  itemTicket,
  regionAttachment,
  marketAttachment,
  isUp,
}) {
  return (
    <div className={`gaf-card ${detailView ? "px-16 pb-16" : ""}`}>
      <div
        className={`flex justify-center items-center gap-3 ${
          detailView ? "" : "border-t-4"
        } border-[#707070]`}
      >
        <img src={`svg/${id}.svg`} width={50} alt={id} />
        <p
          className={`gaf-card-title ${
            detailView ? "text-xl" : "text-lg h-28 flex items-center"
          } font-extrabold`}
        >
          {title}
        </p>
      </div>
      {detailView === true && (
        <div className="py-5 text-center">
          <p className="text-5xl text-[#D71920]">${itemTicket}</p>
          <small className="text-xs">FROM AN AVERAGE 26 SQUARE ROOF</small>
        </div>
      )}
      <div className="flex flex-col grow justify-center py-3 bg-[#f6f6f6] text-black">
        <div className="flex flex-col">
          {detailView === true && (
            <p className="text-center text-xl">ATTACHMENT RATE</p>
          )}
          <Item
            className={`gaf-card-total border-b-2 border-[#D9D9D9] ${
              detailView ? "bg-[#D71920] text-stone-50" : ""
            }`}
          >
            <Title variant="">{dataSource} TOTAL</Title>
            <div className="flex items-center justify-center">
              <p
                className={`gaf-card-total text-4xl text-center ${
                  isUp ? "text-green" : "text-[#D71920]"
                } `}
              >
                {totalStore} %
              </p>
            </div>
          </Item>

          <div>
            <div className="flex justify-center px-3 gap-4 border-[#D9D9D9]">
              <Item className="py-1">
                <Title variant="">BYO</Title>
                <p className={`text-center text-[15.5px]`}>
                  {(regionAttachment * 100).toFixed(2)}%
                </p>
              </Item>
              <div className="w-0.5 bg-[#D9D9D9]" />
              <Item className="py-1">
                <Title variant="">BYO RANK</Title>
                <p className={`text-center text-[15.5px]`}>
                  {regionRank} of {totalRegion}
                </p>
              </Item>
            </div>
            {dataSource === "store" && (
              <>
                <div className="flex justify-center px-3 gap-4 border-t-2 border-[#D9D9D9]">
                  <Item className="py-1">
                    <Title variant="">MARKET</Title>
                    <p className={`text-center text-[15.5px]`}>
                      {(marketAttachment * 100).toFixed(2)}%
                    </p>
                  </Item>
                  <div className="w-0.5 bg-[#D9D9D9]" />
                  <Item className="py-1">
                    <Title variant="">MARKET RANK</Title>
                    <p className={`text-center text-[15.5px]`}>
                      {marketRank} of {totalMarket}
                    </p>
                  </Item>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
