import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";

import componentsData from "../../assets/components";

// const { ipcRenderer } = window.require("electron");

function Kiosk2() {
  const [component, setComponent] = useState(1);
  const [totalValue, setTotalValue] = useState(0);
  const [components, setComponents] = useState(componentsData);
  const videoPlayer = useRef(null);

  const handleComponent = (item) => {
    setComponent(item.id);
    videoPlayer.current.currentTime = item.time;
  };

  const onTimeUpdate = () => {
    for (let i = 0; i < components.length; i++) {
      if (
        components[i].startTime < videoPlayer.current.currentTime &&
        components[i].endTime > videoPlayer.current.currentTime
      )
        setComponent(i + 1);
    }
    // if (videoPlayer.current.currentTime > components[component].startTime)
    //   setToggle(true);
    if (videoPlayer.current.currentTime > components[component].endTime) {
      setComponent(0);
      // setToggle(false);
    }
    // if (
    //   videoPlayer.current.currentTime >
    //   components[Object.keys(components).length].endTime
    // ) {
    //   // setComponent(0);
    //   // setToggle(false);
    // }
  };

  useEffect(() => {
    const newData = componentsData.map((data) => {
      if (data.id < component) return { ...data, isComplete: true };
      else return data;
    });
    setComponents(newData);
    let value = 0;
    for (let i = 0; i < component; i++) {
      value += components[i].ticketAmount;
    }
    setTotalValue(value);
  }, [component, components]);

  return (
    <div className="App bg-black flex relative">
      <div className="flex justify-center items-center">
        <video
          ref={videoPlayer}
          src="Roof_System_master-01.mp4" // You can download video from https://phpstack-264451-3122782.cloudwaysapps.com/Roof_System_master-01.mp4 and add to build/public folder or temporarily change src here to use cloud version
          //src="https://phpstack-264451-3122782.cloudwaysapps.com/Roof_System_master-01.mp4"
          autoPlay
          loop
          onTimeUpdate={() => onTimeUpdate()}
        />
      </div>
      <div className="h-screen w-1/4 bg-white relative flex flex-col justify-center">
        <div className="text-red-500 font-semibold px-6 py-2">
          SYSTEM COMPONENTS
        </div>
        {components?.map((item, index) => (
          <div
            className={`px-6 cursor-pointer py-2 border-t relative ${
              component === item.id
                ? "bg-red-500 text-white"
                : item.isComplete === true
                ? "text-green-500"
                : ""
            }`}
            key={index}
            onClick={() => handleComponent(item)}
          >
            <div className="flex justify-start items-center">
              {item.isComplete && (
                <img
                  className="mr-4"
                  src="png/Check.png"
                  alt="check"
                  width="20"
                  height="40"
                />
              )}
              <p>
                {item.name} {item.isComplete && `$${item.ticketAmount.toFixed(2)}`}
              </p>
            </div>
            {component === item.id ? (
              <p className="text-2xl text-left">${item.ticketAmount.toFixed(2)}</p>
            ) : (
              <></>
            )}
            {component === item.id ? (
              <>
                <div className="absolute -left-[24px] top-[12px] arrow-left-white" />
                <div className="absolute -left-[20px] top-[16px] arrow-left" />
              </>
            ) : (
              <></>
            )}
          </div>
        ))}
        <div className="flex flex-col w-full justify-center items-start absolute bottom-0 px-6 py-6 bg-slate-200 text-red-500 ">
          <p className="font-semibold">TOTAL:</p>
          <p className="font-semibold text-4xl">${totalValue.toFixed(2)}</p>
        </div>
      </div>

      <Link className="absolute left-0 bottom-0 w-14 h-14" to="/" />
    </div>
  );
}

export default Kiosk2;
