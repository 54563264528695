import React, { useState } from "react";

const Button = ({ num, isActive, onClick }) => {
  const [isHover, setIsHover] = useState(false);
  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };
  return (
    <button
      className={`flex items-center justify-center rounded-full w-20 h-20 hover:text-white hover:shadow-2xl hover:bg-red-500 hover:border-4 hover:border-white ${
        isActive ? "bg-red-500 border-4 border-white shadow-2xl" : ""
      }`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={onClick}
    >
      <img
        src={`svg/${isHover || isActive ? "button" : ""}${num}.svg`}
        className="w-14 h-14"
        alt={num}
      />
    </button>
  );
};

export default Button;
