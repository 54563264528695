import React from "react";
import { Link } from "react-router-dom";

export default function Header({ title }) {
  return (
    <div className="flex items-center border-b capitalize">
      { process.env.REACT_APP_MODE!="shareable" &&
      <Link to="/kiosk3">
        <img src="svg/gaf_logo.svg" width={50} alt="logo" />
      </Link>
      }
      { process.env.REACT_APP_MODE=="shareable" && <>
        <img src="svg/gaf_logo.svg" width={50} alt="logo" />
        </>
      }
      <div className="flex w-full text-2xl justify-center items-center font-semibold text-center">
        {title}
      </div>
    </div>
  );
}
