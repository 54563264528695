import React from "react";

export default function RoofCard({ children, setDetailView }) {
  return (
    <div className="roof-card max-w-[30vw] float-right border-8 border-[#D71920] bg-stone-50 m-12 animate fade delay-4 absolute top-0 right-0">
      <button
        className="text-[#D71920] flex justify-end p-2.5 w-full"
        onClick={() => setDetailView(false)}
      >
        X
      </button>
      {children}
    </div>
  );
}
